
import { useRef, useEffect, useState } from "react";




export default function Features(){
  
    const featureul = useRef(null); //ul 元素
    const firstChildRef = useRef(); //
    const secondChildRef = useRef();
    

    const [hitTop, setHitTop] = useState(false); // 依據元素是否進入視窗，設定布林值
    const [isInView, setIsInView] = useState(false);
    const [isInView2, setIsInView2] = useState(false);

    let aa = useRef(null); //用來儲存 ul offsetTop 
    let firstImgHeight = useRef(null);
    let secondImgHeight = useRef(null);
   
    
   
    useEffect(() => {
        aa.current = featureul.current.offsetTop;
        firstImgHeight.current = firstChildRef.current.offsetTop;
        secondImgHeight.current = secondChildRef.current.offsetTop;
        
        window.addEventListener("scroll", () => {
             changeSticky();
             setSlidein(firstImgHeight.current, setIsInView);
             setSlidein(secondImgHeight.current, setIsInView2);

             return () => {
                console.log('clean up')
             }
        }, [])
        
            

    function changeSticky(){
        
        if (window.scrollY >= aa.current ) {
            setHitTop(true);
          } else{
            setHitTop(false);
          }
    
    }

    function setSlidein(height, setter){

        if(window.scrollY  >=  height ){
            setter(true);
        } else{
            setter(false);
        }
    }
    
    
})
 
    


   


    return(
        <>
        
        <div className="feature-div" id="Features">
            <h2>關於我</h2>
            <ul className="feature-ul" ref={featureul}>
            <FirstItem    hitTop={hitTop} view={isInView} firstImg={ firstChildRef }> 
                
            </FirstItem>
            <SecondItem hitTop={hitTop} view={isInView2} secondImg={ secondChildRef}>

            </SecondItem>

            <ThirdItem hitTop={hitTop} >

            </ThirdItem>
           
            </ul>
            
        </div>
        </>
    )
}

// 子元件-投影片內容

// 第一頁

function FirstItem({hitTop, view, firstImg}){
   

    return (
    
            <li  id="firstitem" className={hitTop ? 'sticky': ''}>
                <h2>工作經驗</h2>
                <div className="feature-slide-div">
                    
                    <div>
                        <h2>本地化工程師，語言服務產業，七年經驗</h2>
                        <hr></hr>
                        <br></br>
                        <h3>我負責的是…</h3>
                        <ul className="feature-slide-detail-ul">
                            <li>
                                <h4>為專案提出工具解決方案、技術支援</h4>
                                <p>專案問題排除、解決方案建立、跨部門溝通</p>               
                            </li>
                            <li>
                                <h4>翻譯資料庫維護</h4>
                                <p>資料庫建立、維護更新</p>
                            </li>
                            <li>
                                <h4>教育訓練、規劃</h4>
                                <p>課程規劃、說明文件撰寫</p>
                            </li>
                        </ul>
                                              
                    </div>
                    <img className="slideImage" src="./demo_01.jpg" alt="demo_01" ref={firstImg}
                    style={{
                        opacity: view ? '1' : '0',
                        transform: view ?  '':'translate(100px, 0px)',
                        transition: 'transform 1s, opacity 1s',

                    }}
                    ></img>
                </div>
                
            </li>

    )

}

// 第二頁

function SecondItem({hitTop, view, secondImg}){


    return (
    
            <li  id="seconditem" className={hitTop ? 'sticky': ''}>
                <h2>為了成為工程師，我做了…</h2>
                
                <div  className="feature-slide-div">
                <img className="slideImage" src="./demo_02.jpg" alt="demo_01" ref={secondImg}
                 style={{
                    opacity: view ? '1' : '0',
                    transform: view ?  '':'translate(-100px, 0px)',
                    transition: 'transform 1s, opacity 1s',

                }}></img>
                    <div>
                        <ul className="feature-slide-detail-ul">
                            <li>
                                <h4>善用線上資源自學</h4>
                                <p>觀看線上課程、說明文件、討論區</p>               
                            </li>
                            <li>
                                <h4>發想與實作</h4>
                                <p>課程引導專案 - 電商網站</p>
                                <p>從興趣發想 - 速寫網站</p>
                            </li>
                            <li>
                                <h4>持續練習、精進</h4>
                                <p>建立習慣、每日學習</p>
                            </li>
                        </ul>
                                              
                    </div>

                    
                </div>
            </li>

    )

}

// 第三頁

function ThirdItem({hitTop}){
    
    return (
    
            <li  id="thirdItem" className={hitTop ? 'sticky': ''}>
                <h2>我目前會的…</h2>
                <div  className="feature-slide-div">
                                 
                    <div>
                        <ul className="feature-slide-detail-ul">
                            <li>
                                <h4>前端相關</h4>
                                <p>HTML、CSS、Javascript</p>               
                            </li>
                            <li>
                                <h4>前端框架</h4>
                                <p>Vue3、React</p>
                             
                            </li>
                            <li>
                                <h4>後端相關</h4>
                                <p>Node.js、Express</p>
                            </li>
                            <li>
                                <h4>資料庫</h4>
                                <p>MySQL、MongoDB</p>
                            </li>
                           
                        </ul>
                                              
                    </div>

                    <div>
                        <ul className="feature-slide-detail-ul">
                            <li>
                                <h4>版本控制</h4>
                                <p>Git</p>
                            </li>
                            <li>
                                <h4>編輯器</h4>
                                <p>Visual Studio</p>
                            </li>
                            <li>
                                <h4>網站部屬</h4>
                                <p>linux 主機(Ubuntu)、Nginx</p>
                            </li>
                    
                        </ul>
                    </div>
                   
                    
                </div>
            </li>

    )

}