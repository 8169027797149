import { useState } from "react";


export default function Projects(){



    return (
        <>
        <div className="project-div" id="Projects">
            <h2>作品集</h2>
            <ul className="project-ul">
                <Projectitems 
                
                imgPath="./project_01_screenshot.png" 
                title="展演活動網站" 
                intro="展演活動與購物網站，根據線上課程引導，加上自我發想完成的專案。"
                liveUrl= "https://project01.andysproject.cc"
                githubUrl= "https://github.com/AndyLiu2023/project_01"
                >
                  <h3>網站功能</h3>
                     
                        <ul className="project-sub-ul">
                            <li>會員建立、登入功能</li>
                            <li>管理員後台功能，編輯商品、活動訊息、訂單</li>
                            <li>購物車功能</li>
                            <li>商品瀏覽、篩選瀏覽、關鍵字搜尋</li>
                            <li>第三方金流，線上刷卡</li>
                        </ul>
                    
                  <h3>應用技能</h3>  
                 
                    <ul className="project-sub-ul">
                        <li>MVC 架構</li>
                        <li>RWD</li>
                        <li>ajax 串接</li>
                        <li>session 功能</li>
                        <li>資料庫建立與 table 結構設計</li>
                        <li>API 串接：google 地圖</li>
                        <li>第三方金流串接：綠界 Node.js SDK</li>
                        <li>部屬網站，linux 虛擬主機設定</li>
                    </ul>
                   
                </Projectitems>
                <Projectitems 
                
                imgPath="./project_02_screenshot.png" 
                title="速寫網站" 
                intro="速寫工具網站，自我發想專案，使用Vue框架，加上DeviantArt API。"
                liveUrl= "https://project02.andysproject.cc"
                githubUrl= "https://github.com/AndyLiu2023/sketch-site"
                >

                  <h3>網站功能</h3>
                    
                    <div className="project-sub-p"> 
                    <h4>速寫模式</h4>
                        <ul className="project-sub-ul">
                            <li>倒數計時功能（30 / 60 / 90 / 120 秒）</li>
                            <li>倒數提示音，最後三秒會響音提示</li>
                            <li>暫停、重新計時功能</li>
                            <li>全螢幕模式</li>
                        </ul>
                    </div>
                    <div className="project-sub-p">
                    <h4>自由模式</h4>
                        <ul className="project-sub-ul">
                            <li>不限時計時，可以停留單張照片</li>
                            <li>磚牆式版面，一次瀏覽複數張參考照片</li>
                            <li>收藏照片，收藏後的照片可在 已收藏 頁面瀏覽</li>
                            <li>瀑布式瀏覽</li>
                        </ul>


                    </div>
                    
                    <div className="project-sub-p">
                        <h4>狗狗模式</h4>
                        <ul className="project-sub-ul">
                            <li>考量到面試官或是想了解這個網站作法的人，操作過程可能不太想看到裸體的人，於是另外寫了狗狗模式，可以把圖片來源換成狗狗。</li>
                        </ul>
                    </div>         
                    
                  <h3>應用技能</h3>  
                    <ul className="project-sub-ul">
                        <li>ajax fetch 串接 API</li>
                        <li>VUE 前端框架應用</li>
                        <li>RWD</li>
                        <li>部屬網站，linux 虛擬主機設定</li>
                    </ul>
                </Projectitems>
                <li>還有這個網站是使用 React 製作的！
                </li>
            </ul>
        </div>
        </>
    )
}


function Projectitems({imgPath, title, intro, children, liveUrl, githubUrl}){

    const [isActive, setIsActive] = useState(false); // 設定toggle


    return(
        <li>
            <div className="project-li-div">
                <div>
                    <img src= {imgPath} alt="demo" className="projectImg"></img>
                </div>
                <div>
                    <h2>{title}</h2>
                    <hr></hr>
                  
                    <p>{intro}</p>
                    <div className="project-a">
                        <ul >
                            <li><a href = {liveUrl} target="_blank"  rel="noopener noreferrer">進入網站</a></li>
                            <li><a href = {githubUrl} target="_blank"  rel="noopener noreferrer">Github</a></li>
                            <li><button onClick={() => setIsActive(!isActive)}
                            >詳細說明</button></li>
                        
                        </ul>
                    </div>
                </div>
 
            </div>
            {/* { isActive ? (
            <div className="expand-div">
            {children}
            </div>):''} */}
         
            
                <div 
                    style={{
                        height: isActive? '40rem' : '0rem',
                        opacity: isActive? '1' : '0',
                        overflow: 'hidden',
                        transition: ' height 0.5s, opacity 1.5s'

                    }}
                
                >
                {children}
                </div>
            
      
        </li>
    )


}

