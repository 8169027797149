
import './App.css';

import Navibar from './components/nav';
import Hero from './components/hero';
import Features from './components/features';
import Projects from './components/project';

function App() {
  return (
    <div className="App" id="App">
      <Navibar />
      <header className="App-header">
       <Hero />
      </header>
      <main className="App-main">
        <Features />
        <Projects />
      </main>
    </div>
  );
}

export default App;
