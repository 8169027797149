export default function Hero(){
    return (
        <>
        <div className='hero-div' id="Hero">
            <div className='speech-bubble'>
                <div className='arrow'></div>
                <h1>我是<span>柳丞奕</span></h1>
                <h2>我想成為前端工程師！</h2>
                
            </div>

            <div className="hero-resume-div">
                <ul className="hero-resume-ul">
                    <li><a href="https://drive.google.com/file/d/1POohOhqSTypGJWtWYL_FdHRYXuku7QbY/view?usp=drive_link" target="_blank"  rel="noopener noreferrer">履歷</a></li>
                    <li><a href="https://drive.google.com/file/d/1vGUzMtUwRbhWyXeHyQRUPBZNRHVuWlT7/view?usp=drive_link" target="_blank"  rel="noopener noreferrer">自傳</a></li>
                </ul>
            </div> 
        </div>
        </>
    )
}