export default function Navibar(){
    return(
        <>
        <nav>
            <ul className="navi">
                <li><a href="#Features">關於我</a></li>
                <li><a href="#Projects">作品集</a></li>
            </ul>
        </nav>
        </>
    )
}